import React from 'react'
import Theme from '../../../util/Theme';
import { ClipLoader } from 'react-spinners';

let MainButton = ({ buttonText, buttonDisabled, buttonType, buttonClick, buttonClass , loading }) => {
    return (
        <>
            <style>
                .btn-primary {`{
                    color: ${Theme.colors.mainColor};
                    border: 1px solid ${Theme.colors.mainColor};
                    border-radius: 5px;
                    padding:5px;
                    background: transparent;
                    cursor: pointer;
                    transition:.3s;
                    font-size: 20px;
                }`}
                .btn-primary:hover{`{
                    background: ${Theme.colors.mainColor};
                    color: #fff;
                    border: 1px solid ${Theme.colors.mainColor};
                }`}
                .loader{`{
                    border-color: ${Theme.colors.mainColor} ${Theme.colors.mainColor} transparent !important;
                }`}
                .btn-primary:hover .loader{`{
                    border-color: #fff #fff transparent !important;
                }`}
                .btn-primary.disabled{`{
                    border: 1px solid ${Theme.colors.mainColor};
                    background-color: #cccccc;
                    color: ${Theme.colors.mainColor};
                }`}
                .btn-primary a{`{
                    padding:10px 5px;
                }`}
            </style>
            <button className={buttonDisabled?`disabled ${buttonClass} btn-primary my-3`:`${buttonClass} btn-primary my-3`} disabled={buttonDisabled} id='main' type={buttonType} onClick={buttonClick} >{loading?<ClipLoader className='loader' size={20}  />:buttonText}</button>
        </>
    )
};

export default MainButton