import { configureStore } from "@reduxjs/toolkit";
import doctors from "./doctorsSlice";
import adds from "./addsSlice";
import branches from "./branchesSlice";

const store = configureStore({
  reducer: {
    doctors,
    adds,
    branches
  },
});

export default store;
