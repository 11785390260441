import { createSlice } from "@reduxjs/toolkit";
import { fetchAdds } from "../services/adds.service";

const initialState = {
  adds: [],
  addsLoading: false,
  addsError: null,
};

const addsSlice = createSlice({
  name: "adds",
  initialState,
  reducers: {},
  extraReducers: {
    //fetch adds
    [fetchAdds.pending]: (state) => {
      state.addsLoading = true;
      state.addsError = null;
    },
    [fetchAdds.fulfilled]: (state, { payload }) => {
      state.addsLoading = false;
      state.addsError = null;
      state.adds = payload;
    },
    [fetchAdds.rejected]: (state, { error }) => {
      state.addsLoading = false;
      state.addsError = error.name;
    },
  },
});

export default addsSlice.reducer;
