import { createSlice } from "@reduxjs/toolkit";
import { fetchDoctors } from "../services/doctors.service";

const initialState = {
  doctors: [],
  doctorsLoading: false,
  doctorsError: null,
};

const doctorsSlice = createSlice({
  name: "doctors",
  initialState,
  reducers: {},
  extraReducers: {
    //fetch doctors
    [fetchDoctors.pending]: (state) => {
      state.doctorsLoading = true;
      state.doctorsError = null;
    },
    [fetchDoctors.fulfilled]: (state, { payload }) => {
      state.doctorsLoading = false;
      state.doctorsError = null;
      state.doctors = payload;
    },
    [fetchDoctors.rejected]: (state, { error }) => {
      state.doctorsLoading = false;
      state.doctorsError = error.name;
    },
  },
});

export default doctorsSlice.reducer;
