import { createSlice } from "@reduxjs/toolkit";
import { fetchBranches } from "../services/branches.service";

const initialState = {
  branches: [],
  branchesLoading: false,
  branchesError: null,
};

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: {
    //fetch branches
    [fetchBranches.pending]: (state) => {
      state.branchesLoading = true;
      state.branchesError = null;
    },
    [fetchBranches.fulfilled]: (state, { payload }) => {
      state.branchesLoading = false;
      state.branchesError = null;
      state.branches = payload;
    },
    [fetchBranches.rejected]: (state, { error }) => {
      state.branchesLoading = false;
      state.branchesError = error.name;
    },
  },
});

export default branchesSlice.reducer;
