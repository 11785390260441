import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import logoImg from "../../Assets/imgs/Logo.png";

const SiteLayout = ({ children }) => {
  return (
    <>
      <Navbar id="navbar" expand="lg">
        <Container>
          <Navbar.Brand>
            <a href="/">
              <img src={logoImg} alt="logo" height="65px" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarCollapse" />
          <Navbar.Collapse id="navbarCollapse">
            <Nav className="me-auto">
              <a href="/">
                <i className="fa-solid fa-home"></i> الصفحة الرئيسية
              </a>
              <a href="#branchesSection">
                <i class="fa-solid fa-location-dot"></i> أفرعنا
              </a>
              <a href="#doctorsSection">
                <i class="fa-solid fa-user-doctor"></i> الأطباء
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {children}
      <footer>
        <Container>
          <Row>
            <Col lg={5} md={12} className="mb-4 aboutus-footer">
              <a href="/" className="mb-3 footer-brand">
                <img src={logoImg} className="img-fluid" alt="logo" />
              </a>
              <p>
                تم انشاء تطبيق (صيدليتي) لضمان وصول المشتركين لجميع الصيدليات
                والتواصل مع خدمة الزبائن للحصول على أفضل الأسعار و الحصول على
                نقاط عند كل عملية شراء لتحويلها إلى خدمات طبية مجانية.
              </p>
            </Col>
            <Col lg={3} md={12} className="mb-4">
              <ul className="footer-contact">
                <li className="mb-3">
                  <h5>تواصل معنا</h5>
                </li>
                <li className="mb-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://goo.gl/maps/JjZv9eGYbB38X1mY6"
                  >
                    <i className="fa-solid fa-location-dot"></i> المعلمين، الرمادي، العراق
                  </a>
                </li>
                <li className="mb-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel:0096407839944994"
                  >
                    <i className="fa-solid fa-phone"></i> 96407839944994+
                  </a>
                </li>
                {/* <li className="mb-1">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:info@assistcompany.net"
                  >
                    <i className="fa-solid fa-envelope"></i>{" "}
                    info@assistcompany.net
                  </a>
                </li> */}
              </ul>
            </Col>
            <Col lg={4} md={12}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3329.977974504555!2d43.30200088480135!3d33.42381858078154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDI1JzI1LjgiTiA0M8KwMTcnNTkuMyJF!5e0!3m2!1sar!2snl!4v1688899123327!5m2!1sar!2snl"></iframe>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="social-media">
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/we.assist.it"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a> */}
              {/* <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/0096407839944994"
              >
                <i className="fa-brands fa-whatsapp"></i>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://t.me/we_assist_it"
              >
                <i className="fa-brands fa-telegram"></i>
              </a> */}
            </Col>
          </Row>
        </Container>
        <Row>
          <Col className="copyright" lg={12}>
            حقوق النشر محفوظة &nbsp;
            <a className="company-name" href="/privacyPolicy">
              صيدلية دار القمة
            </a>
            &nbsp;  2023 &copy;
          </Col>
        </Row>
      </footer>
    </>
  );
};

export default SiteLayout;
