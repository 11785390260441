import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import Home from "./pages/Site/Home";
import ErrorPage from "./pages/Errors/ErrorPage";
import PrivacyPolicy from "./pages/Site/PrivacyPolicy";

const Router = () => {
  const router = createBrowserRouter([
    { path: "", element: <Home />, errorElement: <ErrorPage /> },
    {
      path: "privacyPolicy",
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
    },
  ]);
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default Router;
