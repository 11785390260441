import { useRouteError, useNavigate } from "react-router-dom";
import MainButton from '../../components/shared/Buttons/MainButton'
import { Col, Row } from "react-bootstrap";
import Theme from "../../util/Theme";

const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  
  
  return (
    <>
     <style>
       .notfound {`{
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 520px;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }`}
  
  .notfound .notfound-bg {`{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: -1;
  }`}
  
   .notfound .notfound-bg div {`{
    width: 100%;
    background: #fff;
    border-radius: 90px;
    height: 125px;
  }`}
  
   .notfound .notfound-bg div:nth-child(1) {`{
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
  }`}
  
   .notfound .notfound-bg div:nth-child(2) {`{
    -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 10;
  }`}
  
   .notfound .notfound-bg div:nth-child(3) {`{
    -webkit-box-shadow: 5px 5px 0px 0px #f3f3f3;
            box-shadow: 5px 5px 0px 0px #f3f3f3;
    position: relative;
    z-index: 90;
  }`}
  
   .notfound h1 {`{
    font-family: 'Quicksand', sans-serif;
    font-size: 86px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
    color: #151515;
  }`}
  
   .notfound h2 {`{
    font-family: 'Quicksand', sans-serif;
    font-size: 26px;
    margin: 0;
    font-weight: 700;
    color: #151515;
  }`}
  .social-media {`{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid #ffffff38;
}`}
.social-media a {`{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background:${Theme.colors.mainColor};
  border: 1px solid ${Theme.colors.mainColor};
  color: #f2f2f2;
  transition: 0.3s;
}`}
.social-media a:hover {`{
  background: ${Theme.colors.secondaryColor};
  border-color:${Theme.colors.secondaryColor};
  color: #f2f2f2;
}`}
    </style>
        <div className="notfound">
          <div className="notfound-bg">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1>oops!</h1>
          <h2>Error 404 : {error.statusText || error.message}</h2>
          <MainButton
              buttonClick={() => navigate("/", { replace: true })}
              buttonText={"Go back"}
           />
          <Row>
            <Col lg={12} className="social-media">
              <a href="https://www.facebook.com/we.assist.it">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a href="https://wa.me/00963994577877">
                <i className="fa-brands fa-whatsapp"></i>
              </a>
              <a href="https://t.me/we_assist_it">
                <i className="fa-brands fa-telegram"></i>
              </a>
            </Col>
          </Row>
        </div>
    </>
    
  );
};

export default ErrorPage;

