import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_LINK } from "./ApiService";

export const fetchAdds = createAsyncThunk(
  "Images/Ads",
  async (_, { rejectedWithValue }) => {
    try {
      const { data } = await axios.get(`${API_LINK}Images/Ads`);
      return data.data;
    } catch (error) {
      return rejectedWithValue(error.message);
    }
  }
);
