import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_LINK } from "./ApiService";

export const fetchDoctors = createAsyncThunk(
  "Doctors",
  async (_, { rejectedWithValue }) => {
    try {
      const { data } = await axios.get(`${API_LINK}Doctors`);
      return data.data;
    } catch (error) {
      return rejectedWithValue(error.message);
    }
  }
);
