const colors = {
  mainColor: "#0343b1",
  secondaryColor: "#0f7d26",
  lightColor: "rgba(212, 212, 212, 0.83)",
  errorColor: "rgba(255, 0, 0, 0.5)",
  whiteColor: "#fff",
  darkColor: "#000",
};

const Theme = {
  colors,
};
export default Theme;
