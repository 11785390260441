import React from "react";
import SiteLayout from "../Layouts/SiteLayout";

const PrivacyPolicy = () => {
  return (
    <SiteLayout>
      <div className="container">
        <div style={{ margin: "100px 0 0" }}>
          <div style={{ margin: "0 0 30px" }}>
            <h2 style={{ margin: "0 0 10px" }}> سياسة الخصوصية</h2>
            <p>
              في موقع صيدليتي، ندرك أن خصوصية معلوماتك الشخصية هامة لك
              ولنا. فيما يلي معلومات حول أنواع المعلومات الشخصية التي نتلقاها
              ونقوم بجمعها عند زيارات صيدليتي، وكيف نقوم بحماية معلوماتك
              الشخصية.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>ملفات السجل</h4>
            <p>
              كما هو الحال مع معظم مواقع الويب المختلفة، نقوم بجمع واستخدام
              البيانات الموجودة في ملفات السجل.
              <br />
              تشمل المعلومات الموجودة في ملفات السجل عنوان بروتوكول الإنترنت
              (IP) الخاص بك، ومزود خدمة الإنترنت (ISP)، والمتصفح الذي استخدمته
              لزيارة موقعنا، والوقت الذي قمت فيه بالزيارة، والصفحات التي قمت
              بزيارتها عبر موقعنا.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>ملفات تعريف الارتباط</h4>
            <p>
              كما هو الحال مع معظم مواقع الويب المختلفة، نقوم بجمع واستخدام
              البيانات الموجودة في ملفات السجل.نحن نستخدم ملفات تعريف الارتباط
              لنمنحك أفضل تجربة أستخدام ممكنة على موقع بسيط، لمزيد من المعلومات
              حول ملفات تعريف الارتباط ، تفضل بزيارة:
              <a
                href="http://www.allaboutcookies.org"
                style={{ color: "#8ab4f8" }}
              >
                http://www.allaboutcookies.org
              </a>
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>
              ملفات تعريف الارتباط الأساسية
            </h4>
            <p>
              تعد ملفات تعريف الارتباط الأساسية ضرورية لك للتنقل والتنقل في جميع
              أنحاء الموقع، ولا تخزن أي معلومات يمكن استخدامها لأغراض الدعاية،
              بدون ملفات تعريف الارتباط الأساسية، لن يعمل موقعنا بشكل صحيح.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>ملفات تعريف الارتباط المخصصة</h4>
            <p>
              تخزن ملفات تعريف الارتباط هذه المعلومات ، مثل تفضيلاتك الشخصية ،
              وتستخدمها لتخصيص تجربة فريدة لك.
              <br />
              قد يشمل ذلك عرض نافذة منبثقة مرة واحدة فقط في زيارتك ، أو حفظ
              تفضيلات اللغة الخاصة بك ، أو السماح لك بتسجيل الدخول تلقائيًا إلى
              بعض ميزاتنا.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>
              ملفات تعريف الارتباط في Analytics
            </h4>
            <p>
              تلتقط ملفات تعريف الارتباط من Analytics بيانات مجهولة المصدر حتى
              نتمكن من رؤية الاتجاهات وتحسين تجربة موقعنا على الويب.
              <br />
              هذه تتيح لنا اختبار تصميمات مختلفة ، وتساعدنا على تحديد الكسر إذا
              كان جزء من موقعنا لا يعمل.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <h4 style={{ margin: "0 0 10px" }}>
              ملفات تعريف الارتباط للإعلانات
            </h4>
            <p>
              قد يستخدم بعض المعلنين من الجهات الخارجية ملفات تعريف الارتباط أو
              إشارات الويب عند الإعلان على موقعنا.
              <br />
              سيقوم هؤلاء بإرسال معلومات إلى هؤلاء المعلنين (مثل Google من خلال
              برنامج Google AdSense) بما في ذلك عنوان IP الخاص بك ، ومزود خدمة
              الإنترنت ، والمتصفح الذي استخدمته لزيارة موقعنا ، وفي بعض الحالات
              معلومات حول ما إذا كنت قد قمت بتثبيت Flash.
            </p>
            <ul>
              <li>
                يستخدم موردو الجهات الخارجية، بمن فيهم Google، ملفات تعريف
                ارتباط لعرض الإعلانات بناءً على زيارات المستخدم السابقة لموقعنا
                الإلكتروني أو لمواقع أخرى على الويب.
              </li>
              <li>
                ستتمكّن Google وشركاؤها، باستخدام ملفات تعريف الارتباط
                للإعلانات، من عرض الإعلانات للمستخدمين لديك استنادًا إلى
                زياراتهم لموقعنا و/أو مواقع أخرى عبر الإنترنت.
              </li>
              <li>
                يمكن للمستخدمين تعطيل استخدام الإعلانات المخصصة عن طريق الانتقال
                إلى{" "}
                <a
                  href="https://www.google.ae/settings/ads"
                  style={{ color: "#8ab4f8" }}
                >
                  إعدادات الإعلانات
                </a>
                .
              </li>
            </ul>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <p>
              إذا لم يتم اختيار{" "}
              <a
                href="https://support.google.com/dfp_premium/answer/94152"
                style={{ color: "#8ab4f8" }}
              >
                {" "}
                تعطيل عرض إعلانات الأطراف الثالثة{" "}
              </a>{" "}
              ، فقد يتم استخدام ملفات تعريف ارتباط{" "}
              <a
                href="https://support.google.com/dfp_premium/answer/94149"
                style={{ color: "#8ab4f8" }}
              >
                {" "}
                موردي أو شبكات إعلانات الأطراف الثالثة{" "}
              </a>
              الأخرى أيضًا لعرض الإعلانات في موقعنا.
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <p>
              يستخدم هذا بشكل عام لأغراض الاستهداف الجغرافي على سبيل المثال (عرض
              الإعلانات SEO في مصر لشخص ما في مصر) أو عرض إعلانات معينة بناءً
              على مواقع محددة تمت زيارتها (مثل عرض إعلانات العقارات لشخص يرتاد
              مواقع العقارات).
            </p>
          </div>
          <div style={{ margin: "0 0 30px" }}>
            <p>
              من خلال الاستمرار في استخدام موقعنا ، فإنك توافق على وضع ملفات
              تعريف الارتباط على جهازك، يمكنك اختيار تعطيل أو إيقاف تشغيل ملفات
              تعريف الارتباط أو ملفات تعريف ارتباط الطرف الثالث بشكل انتقائي في
              إعدادات المتصفح الخاص بك. ومع ذلك ، يمكن أن يؤثر هذا على كيفية
              تفاعلك مع موقعنا وكذلك مع مواقع الويب الأخرى.
              <br />
              اذا كان لديك اى سؤال او كان هنا اى مشكلة فى سياسة أستخدام البيانات
              الخاصة بنا الرجاء المراسلة ع من خلال صفحة الأتصال بنا.
            </p>
          </div>
        </div>
      </div>
    </SiteLayout>
  );
};

export default PrivacyPolicy;
