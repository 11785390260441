import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_LINK } from "./ApiService";

export const fetchBranches = createAsyncThunk(
  "Branches/main",
  async (_, { rejectedWithValue }) => {
    try {
      const { data } = await axios.get(`${API_LINK}Branches/main`);
      return data.data;
    } catch (error) {
      return rejectedWithValue(error.message);
    }
  }
);
