import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import FullScreenLoading from "../../components/shared/Loading/FullScreenLoading";
import SiteLayout from "../Layouts/SiteLayout";
import MainButton from "../../components/shared/Buttons/MainButton";
import logoImg from "../../Assets/imgs/Logo.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdds } from "../../services/adds.service";
import { useEffect } from "react";
import { fetchDoctors } from "../../services/doctors.service";
import { fetchBranches } from "../../services/branches.service";
import { API_LINK_STORAGE } from "../../services/ApiService";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

const Home = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const { adds, addsLoading, addsError } = useSelector((state) => state.adds);

  const { doctors, doctorsLoading, doctorsError } = useSelector(
    (state) => state.doctors
  );

  const { branches, branchesLoading, branchesError } = useSelector(
    (state) => state.branches
  );

  useEffect(() => {
    dispatch(fetchAdds());
    dispatch(fetchDoctors());
    dispatch(fetchBranches());
  }, [dispatch]);

  return (
    <FullScreenLoading
      loading={addsLoading || doctorsLoading || branchesLoading}
      error={addsError || doctorsError || branchesError}
    >
      <SiteLayout>
        <>
          {/* add section  */}
          <section id="addSection">
            <Swiper
              loop={true}
              autoplay={{
                delay: 2500,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {adds.map((item, idx) => (
                <SwiperSlide key={idx}>
                  <div className="add-slide">
                    <img src={API_LINK_STORAGE + item.url} alt="add" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          {/* end add section  */}

          {/* branches section */}
          <section id="branchesSection">
            <Container>
              <h2 className="section-title">أفرعنا</h2>
              {/* <h6 className="section-description">اطباءنا الخاص بنا</h6> */}
              <Row className="justify-content-around" style={{ width: "100%" }}>
                {branches.map((item, idx) => (
                  <Col lg={4} md={6} key={idx} className="branch-container">
                    <div className="branch">
                      <div className="branch-imgs">
                        <Swiper
                          loop={true}
                          autoplay={{
                            delay: 2500,
                          }}
                          modules={[Autoplay]}
                          className="mySwiper"
                        >
                          {item.images.map((itemImg, idxImg) => (
                            <SwiperSlide key={idxImg}>
                              <div className="branch-slide">
                                <img
                                  src={API_LINK_STORAGE + itemImg.url}
                                  alt="branch"
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <div class="branch-info text-center">
                        <h4 className="name">{item.name}</h4>
                        <h4 className="address my-3"><i class="fa-solid fa-location-dot"></i> {item.address}</h4>
                      </div>
                    </div>
                    {/* <MainButton
                      buttonClick={handleShow}
                      buttonText={<i className="fa-solid fa-location-dot"></i>}
                    />
                    <Modal show={show} onHide={handleClose}>
                      <div className="map">
                      <iframe src="https://maps.google.com/maps?q=40.7128,74.0060&hl=en&z=15&amp;output=embed" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </Modal> */}
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
          {/* end branches section */}

          {/* about section */}
          <section id="aboutSection">
            <Container>
              <div className="about-content">
                <h2 className="title">من نحن</h2>
                <p className="description">
                  تم انشاء تطبيق (صيدليتي) لضمان وصول المشتركين لجميع الصيدليات
                  والتواصل مع خدمة الزبائن للحصول على أفضل الأسعار و الحصول على
                  نقاط عند كل عملية شراء لتحويلها إلى خدمات طبية مجانية. وكذلك
                  سهولة الوصول للأطباء وعناوينهم وباقي الخدمات الطبية في
                  المجمعات الطبية. يقدم تطبيق (صيدليتي ) خدمة أشتراك الأمراض
                  المزمنة والذي يوفر ملف خاص بكل مريض بالادوية المزمنة التي
                  يستخدمها بصورة مستمرة للحصول على خصومات خاصة وفحوصات وتحاليل
                  مجانية من خلال التطبيق.
                </p>
              </div>
              <div className="about-img">
                <img src={logoImg} alt="about" />
              </div>
            </Container>
          </section>
          {/* end about section */}

          {/* doctors Section */}
          <section id="doctorsSection">
            <Container>
              <h2 className="section-title">الاطباء</h2>
              {/* <h6 className="section-description">اطباءنا الخاص بنا</h6> */}
              <Swiper
                spaceBetween={50}
                pagination={{ clickable: true }}
                navigation={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1080: {
                    slidesPerView: 4,
                  },
                }}
                className="mySwiper doctors-swiper"
              >
                {doctors.map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <div class="item" data-aos="fade-up" data-aos-delay="100">
                      <div class="item-img">
                        <img
                          src={API_LINK_STORAGE + item?.image?.url}
                          class="img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="item-info">
                        <h4>{item.firstName +" "+ item.lastName}</h4>
                        {/* <span>{item.description}</span> */}
                        <div className="details">
                          <div className="branch">
                            <i class="fa-solid fa-map-location-dot"></i>{" "}
                            {item.branch.name}
                          </div>
                          <div className="specialization">
                            <i class="fa-solid fa-stethoscope"></i>{" "}
                            {item.specialization.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Container>
          </section>
          {/* end doctors Section */}
        </>
      </SiteLayout>
    </FullScreenLoading>
  );
};

export default Home;
