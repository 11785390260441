import React from "react";
import { RingLoader } from "react-spinners";
import Theme from "../../../util/Theme";
import MainButton from "../Buttons/MainButton";

let FullScreenLoading = ({ loading , error , children }) => {
  return loading ? (
    <div
      style={{
        height: "100vh",
        position: "absolute",
        width: "100vw",
        top: "0",
        left: "0",
        background: "#fff",
        zIndex: "100",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <RingLoader
        loading={RingLoader}
        color={Theme.colors.mainColor}
        size={100}
      />
    </div>
  ) : error ? (
    <div
      style={{
        height: "100vh",
        position: "absolute",
        width: "100vw",
        top: "0",
        left: "0",
        background: "#fff",
        zIndex: "100",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <MainButton
        buttonText={
          <>
            إعادة تحميل <i class="fa-solid fa-arrow-rotate-right"></i>
          </>
        }
        buttonClick={() => {
          window.location.reload();
        }}
      />
    </div>
  ) : (
    children
  );
};
export default FullScreenLoading;
